import React from 'react'
import AdminTarifsModule from '../../../Modules/AdminModules/AdminTarifsModule/AdminTarifsModule'

const AdminTarifsPage = () => {
  return (
    <div>
      <AdminTarifsModule/>
    </div>
  )
}

export default AdminTarifsPage
