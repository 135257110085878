import React, { useEffect, useRef, useState } from "react";
import "./AdminMenuMobi.scss";
import logo from "../../../Assets/General/Img/logo_nets.svg";
import icon from "../../../Assets/Admin/img/Header/Vector.svg";
import notificationIcon from "../../../Assets/Admin/img/Header/notification-bing.svg";
import { adminMenuLink } from "../../../Modules/AdminModules/AdminMenuModule/AdminMenuLogic/AdminMenuLogic";
import { NavLink } from "react-router-dom";
const AdminMenuMobi = () => {
  const [active, setActive] = useState(false);
  const topMenuRef = useRef(null);
  const menuToggleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (topMenuRef.current && !topMenuRef.current.contains(event.target)) {
        setActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closeMenu = () => {
    menuToggleRef.current.checked = false;
  };

  return (
    <div className="header__subtitle">
      <div className="header__container">
        <div className="header__logo">
          <img src={logo} alt="" />
        </div>
        <div className="menu__block">
          <input id="menu__toggle" type="checkbox" ref={menuToggleRef} />
          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>

          <div className="menu__box">
            <div className="menu__box__top">
              <div className="adminHeader_item">
                <div className="adminHeaderIcon_block">
                  <img src={icon} alt="" />
                </div>
                <div className="adminHeaderNotificationIcon_block">
                  <div className="adminHeaderNotificationIcon_circle"></div>
                  <img src={notificationIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="menu__box__bottom">
              <h1>Разделы</h1>

              {adminMenuLink?.map((e) => (
                <NavLink to={e.link} key={e.id} className={'adminMenu_link'}>
                  <div className="menu__box__bottom_item">
                    {e.icon}
                    <p>{e.text}</p>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMenuMobi;
