import React from 'react'
import './AdminMenu.scss'
import AminMenuDataCard from './AminMenuDataCard/AminMenuDataCard'
import AdminMenuLinkCard from './AdminMenuLinkCard/AdminMenuLinkCard'

const AdminMenu = () => {
  return (
    <div className='AdminMenu_container'>
       <AminMenuDataCard/>
       <AdminMenuLinkCard/>
    </div>
  )
}

export default AdminMenu
