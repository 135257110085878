import icon from '../../../../Assets/Admin/img/Menu/category-2.svg'
import { MdAutoAwesomeMosaic } from "react-icons/md";
import { MdAccessTime } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { MdBolt } from "react-icons/md";

export const adminMenuLink = [
    {id: 1, icon: <MdAutoAwesomeMosaic className='adminMenu_icon'/>,  text: 'Главная', line: true , link: '/adminHome'},
    {id: 2, icon: <MdAccessTime className='adminMenu_icon'/>,  text: 'История', line: true, link: '/adminHistory' },
    {id: 3, icon: <MdOutlineWifi className='adminMenu_icon'/>,  text: 'Тарифы' , line: true, link: '/adminTarifs'},
    {id: 4, icon: <MdBolt className='adminMenu_icon'/>,  text: 'Услуги', line: false , link: '/AdminServices'},
]