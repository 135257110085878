import React from 'react'
import AdminHome from '../../../Components/Admin/AdminHome/AdminHome'

const AdminHomeModule = () => {
  return (
    <div>
      <AdminHome/>
    </div>
  )
}

export default AdminHomeModule
