import React from "react";
import "./AdminHomeTarrifCard.scss";
import MyButton from "../../../../UI/MyButton/MyButton";
import iconWifi from '../../../../Assets/Home/img/Tarifs/Frame 3.png'
const AdminHomeTarrifCard = () => {
  return (
    <div className="adminHomeTarrifCard_block">
      <div className="adminHomeTarrifCardTop_block">
        <div className="adminHomeTarrifCardTop_item">
          <h1>Тариф:</h1>
          <h2>NETS 2 (6 месяцев)</h2>
        </div>
        <MyButton
          name={"сменить тариф"}
          width={"162px"}
          height={"45px"}
          size={"14px"}
        />
      </div>
      <p>
        Домашний интернет для небольших потребностей в потоковом видео и
        онлайн-играх.
      </p>
      <div className="adminHomeTarrifCardSpeed_block">
        <div className="adminHomeTarrifCardSpeed_item">
            <img src={iconWifi} alt="" />
            <h3>до 5 Мбит/с</h3>
        </div>
            <div className="tarifCardloadding_block">
          <div
            className="tarifCardloadding_line"
            style={{ width:  '60%' }}
          ></div>
        </div>
      </div>
      <div className="adminHomeTarrifCardBalance_text">
        <h6>Ежемесячный платеж:</h6>
        <h2>125 TJS/мес</h2>
      </div>
    </div>
  );
};

export default AdminHomeTarrifCard;
