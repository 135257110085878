import React, { useState } from 'react'
import './HomeApplicationForm.scss'
import axios from 'axios'
const HomeApplicationForm = () => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [showSeccesMessage, setShowSuccessMessage] = useState(false)
  const addForm = () => {
    axios.post('https://nets.tj/add_deal', {"name": name, "phone": phone, "interested_in": 677}).then(res => {
      setShowSuccessMessage(true)
      setName('')
      setPhone('')
      setTimeout(()=> {
        setShowSuccessMessage(false)
      },2000)
    }).catch(err=> {
      console.log(err)
    })
  }
  return (
    <div className="homeApplicationForm">
    <div className='homeApplicationForm_block'>
      <div className="homeApplicationForm_input">
        <p>Имя</p>
        <input type="text" placeholder='Ваше имя'value={name} onChange={(e)=> setName(e.target.value)}/>
      </div>
      <div className="homeApplicationForm_input">
         <p>Номер телефон</p>
         <div className="homeApplicationFormInput_item">
            <p>+992</p> 
            <input type="number" value={phone} onChange={(e)=> setPhone(e.target.value)}/>
         </div>
      </div>
      <button onClick={()=> addForm()}>Отправить</button>
    </div>
    {
      showSeccesMessage &&
      <div className="succes_block">
        <p>Ваше сообщение доставлено. Мы свяжемся с вами в течение 24 часов.</p>
      </div>
        }
    </div>
  )
}

export default HomeApplicationForm
