import React from 'react'
import './AdminHistory.scss'  
import HomeGeneralSlider from '../../Home/HomeGeneralSlider/HomeGeneralSlider'
import AdminHistoryList from './AdminHistoryList/AdminHistoryList'
import AdminHeaderModule from '../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule'
import AdminMenuModule from '../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule'
const AdminHistory = () => {
  return (
    <div className='adminHistory_container'>
       <AdminHeaderModule/>
       <div className="adminHistory_block">
        <AdminMenuModule/>
        <div className="adminHistory_item">
            <HomeGeneralSlider/>
            <div className="adminHistory_card">

            <AdminHistoryList/> 
            </div>
        </div>
       </div>
        
    </div>
  )
}
export default AdminHistory
