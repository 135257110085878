import React from 'react'
import './AminMenuDataCardText.scss'
const AminMenuDataCardText = ({title, subtitle}) => {
  return (
    <div className='aminMenuDataCardText_block'>
       <h3>{title}</h3>
       <p>{subtitle}</p>
    </div>
  )
}

export default AminMenuDataCardText
