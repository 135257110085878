import React from "react";
import "./AdminHistoryList.scss";
import { adminHistoryList } from "../../../../Modules/AdminModules/AdminHistoryModule/AdminHistoryLogic/AdminHistoryLogic";
import AdminHistoryListItem from "./AdminHistoryListItem/AdminHistoryListItem";
const AdminHistoryList = () => {
  
  return (
    <div className="adminHistoryList_block">
      <div className="adminHistoryList_title">
         <h3>Расходы <span>за </span></h3>
         <select name="" id="">
            <option value="">Декабрь</option>
         </select>
      </div>
      <div className="adminHistoryList_item">
        {
            adminHistoryList?.map((e)=> 
              <AdminHistoryListItem title={e.title} subTitle={e.subTitle} centerTitle={e.centerTitle} balance={e.balance} key={e.id}/>
            )
        }
      </div>
    </div>
  );
};

export default AdminHistoryList;
