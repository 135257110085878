import React from 'react'
import './AminMenuDataCard.scss'
import AminMenuDataCardText from './AminMenuDataCardText/AminMenuDataCardText'
const AminMenuDataCard = () => {
  return (
    <div className='aminMenuDataCard_block'>
      <AminMenuDataCardText title={'Имя'} subtitle={'Фио'}/>
      <AminMenuDataCardText title={'Лицевой счет'} subtitle={'123567'}/>
      <AminMenuDataCardText title={'Номер телефона'} subtitle={'123567'}/>
    </div>
  )
}

export default AminMenuDataCard
