import React from 'react'
import AdminHistory from '../../../Components/Admin/AdminHistory/AdminHistory'

const AdminHistoryModule = () => {
  return (
    <div>
      <AdminHistory/>
    </div>
  )
}

export default AdminHistoryModule
