import React from 'react'
import './AdminHomePackages.scss'
import MyButton from '../../../../UI/MyButton/MyButton'
import AdminHomePackagesCard from './AdminHomePackagesCard/AdminHomePackagesCard'
const AdminHomePackages = () => {
  return (
    <div className='adminHomePackages_block'>
      <div className="adminHomePackagesTop_block">
        <h1>Пакеты:</h1>
        <MyButton name={'Посмотреть доступные'} width={'220px'} height={'45px'} size={'14px'}/>
      </div>
      <div className="adminHomePackages_item">
        <AdminHomePackagesCard/>
        <AdminHomePackagesCard/>
      </div>
    </div>
  )
}

export default AdminHomePackages
