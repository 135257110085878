import React from 'react'
import './AdminMenuLinkCardText.scss'
import { NavLink } from 'react-router-dom'
const AdminMenuLinkCardText = ({icon, text, line, link}) => {
  return (
    <NavLink to={link} className='adminMenuLinkCardText_block' style={{borderBottom: line && '1px solid #E1E1E1'}}>
      {icon}
      <p >{text}</p>
    </NavLink>
  )
}

export default AdminMenuLinkCardText
