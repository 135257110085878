import React, { useEffect, useState } from 'react'
import './AdminTarifsCard.scss'
import { adminTarifsTitle, tarifAdminYear } from '../../../../Modules/AdminModules/AdminTarifsModule/AdminTarifsLogic/AdminTarifsLogic';
import { NextArrow, PrevArrow } from '../../../Home/Tarifs/TarifsCards/TarifCard/Arrows/Arrows';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import AdminTarifsCardItem from './AdminTarifsCardItem/AdminTarifsCardItem';
const AdminTarifsCard = ({adminTarifs}) => { 
  const [activeTitle, setActiveTitle] = useState(0);
  const settingsut = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: activeTitle !== 2 ? true : false,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />, 
    responsive: [
      {
        breakpoint: 1280,  
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,  
          nextArrow: false,
          prevArrow: false, 
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1170,  
        settings: {
          slidesToShow: 1,  
          slidesToScroll: 1,  
          nextArrow: false,
          prevArrow: false, 
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false, 
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: true, 
          arrows: false, 
        },
      },
    ],
  };
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));   
  }, []);
  return (
    <div className='tarifsCards__block'>
     <div className="tarifs_title">
          {adminTarifsTitle?.map((e, index) => (
            <div className="tarifsTitle_text" key={index}>
              <p
                style={{ borderBottom: activeTitle === index && '2px solid #FF1749' }}
                onClick={() => setActiveTitle(index)}
              >
                {e.name}
              </p>
            </div>
          ))}
        </div>
      <div className="tarifsCard__item">
        {/* <TarifCard tarifCards={e} show={setShowModal} key={e.id}/> */}
      <Slider {...settingsut} >
   
       {
        activeTitle === 0 ? 
         adminTarifs?.map((e)=> 
         <AdminTarifsCardItem tarifCards={e} />

        ) : 
        
        tarifAdminYear?.map((e)=> 
         <AdminTarifsCardItem tarifCards={e} />
        )
       }


      </Slider>
      </div>
  </div>
  )
}

export default AdminTarifsCard
