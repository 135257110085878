import React from 'react'
import AdminHistoryModule from '../../../Modules/AdminModules/AdminHistoryModule/AdminHistoryModule'

const AdminHistoryPage = () => {
  return (
    <div>
      <AdminHistoryModule/>
    </div>
  )
}

export default AdminHistoryPage
