import React from 'react'
import './AdminHistoryListItem.scss'

const AdminHistoryListItem = ({ title, subTitle, centerTitle, balance }) => {
  return (
    <div className="adminHistoryListItem_block">
    <div className="adminHistoryListNameItem_block">
      <h3>{title}</h3>
      <h2>{balance}</h2>
    </div>
    <div className="adminHistorySubTitleItem_block">
      <div className="adminHistorySubTitleItem_item">
       <div className="adminHistorySubTitleItem_circle"></div>
      <p>{subTitle}</p>
      </div>
      <p>{centerTitle}</p>
    </div>
  </div>
  )
}

export default AdminHistoryListItem
