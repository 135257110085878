export const handleError = (error, navigate) => {
    switch (error.statusCode) {
        case 400:
          alert('Ошибка на стороне клиента');
          break;
        case 401:
          navigate('/login');
          break;
        case 403:
          alert('У вас нет доступа')
          break;
        case 404:
          navigate('/');
          break;
        case 500:
          alert('Ошибка сервера');
          break;
        default:
          console.error(`Unhandled error status code: ${error.statusCode}`, error);
      }
}