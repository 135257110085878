import React, { useEffect, useState } from 'react'
import './AdminServicesCard.scss'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";  
import { NextArrow, PrevArrow } from '../../../Home/Tarifs/TarifsCards/TarifCard/Arrows/Arrows';
import { Form } from 'react-router-dom';
import TarifCustomCard from '../../../Home/Tarifs/TarifsCards/TarifCustomCard/TarifCustomCard';
import { servicesAdminData } from '../../../../Modules/AdminModules/AdminServicesModule/AdminServicesLogic/AdminServicesLogic';
const AdminServicesCard = () => {
    
  
    const [showModal, setShowModal] = useState(false)
 
  
 
  
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      autoplay: 1,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      arrows: true,
      lazyLoad: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />, 
      responsive: [
        {
          breakpoint: 1280,  
          settings: {
            slidesToShow: 1,  
            slidesToScroll: 1,  
            nextArrow: false,
            prevArrow: false, 
            arrows: false,
            dots: true,
          },
        },
        {
          breakpoint: 1124,  
          settings: {
            slidesToShow: 1,  
            slidesToScroll: 1,  
            nextArrow: false,
            prevArrow: false, 
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            nextArrow: false,
            prevArrow: false, 
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: false,
            prevArrow: false,
            dots: true, 
            arrows: false, 
          },
        },
      ],
    };
    useEffect(() => {
      window.dispatchEvent(new Event('resize'));   
    }, []);
  return (
<div className='servicesCards__block'>
    <Slider {...settings} >
    
    {
       
      
   
       servicesAdminData?.map((e)=> 
        <>
        <TarifCustomCard img={e.img} title={e.title} subTitle={e.subTitle} data={e.data} price={e.price} longText={e.longText} key={e.id} show={setShowModal} dataText={e.dataText}/>
        </>
       ) 
    }

{
        showModal &&
      <div className="form_container">
        <div className="form_block">
          <Form show={showModal} setShowModal={setShowModal} id={677} endpoint={'add_deal'}/>
        </div>
      </div>
      }
    </Slider>
    
  </div>
  )
}

export default AdminServicesCard
