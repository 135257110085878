import React from "react";
import "./AdminHomeBalanceCard.scss";
import MyButton from "../../../../UI/MyButton/MyButton";
const AdminHomeBalanceCard = () => {
  return (
    <div className="adminHomeBalanceCard_block">
      <div className="adminHomeBalanceCardTop_block">
        <div className="adminHomeBalanceCardTop_item">
          <h2>Баланс:</h2>
          <h3>25.70</h3>
        </div>
        <MyButton name={'+Пополнить'} width={'142px'} height={'45px'} size={'14px'}/>
      </div>
      <div className="adminHomeBalanceCardTarif_block">
          <h4>Название тарифа</h4>
          <p>Активен до 23.09.24</p>
      </div>
      <h6>Данные актуальны на сегодня 13:13</h6>
    </div>
  );
};

export default AdminHomeBalanceCard;
