import React from "react";
import "./PrivacyPolicy.scss";
import Menu from "../../Components/General/Menu/Menu";
import Footer from "../../Components/General/Footer/Footer";
const PrivacyPolicy = () => {
  return (
    <div class="terms_container">
      <Menu />
      <div class="terms_block">
        <div class="terms_item">
          <h1>
            Политика конфиденциальности персональных данных пользователей
            мобильного приложения Nets
          </h1>
          <p>
            Настоящая Политика конфиденциальности персональных данных
            пользователей мобильного приложения «Nets» (далее – «Политика»)
            принята ООО «Нет Солюшенс», (ИНН: 020047495, ЕИН: 0210023422, адрес
            местонахождения: 734242, Республика Таджикистан, г. Душанбе, ул.
            Тилло Пулоди, 41) (далее — «Компания») и действует в отношении всей
            информации, которую Компания может получить о Пользователи
            приложения «Nets» с любого устройства и при коммуникации с
            Компанией.
          </p>
          <p>
            Использование Мобильного Приложения «Nets» (далее – МП) (просмотр,
            чтение текста, отправка или загрузка информации) означает
            безоговорочное согласие пользователя с настоящей Политикой и
            указанными в ней условиями обработки его персональных данных. В
            случае, несогласия с этими условиями пользователь должен
            воздержаться от использования Сервисов.
          </p>
          <h1>Понятия и состав персональных данных</h1>
          <p>
            <span>персональные данные –</span> любая информация, относящаяся
            прямо или косвенно к определенному или определяемому физическому
            лицу (субъекту персональных данных);
          </p>
          <p>
            <span>обработка персональных данных –</span> любое действие
            (операция) или совокупность действий (операций) с персональными
            данными, совершаемое с использованием средств автоматизации или без
            их использования.
          </p>
          <p>
            Компания производит обработку персональных данных Пользователя МП,
            включая фамилию, имя, отчество, дату и место рождения, контактный
            телефон, реквизиты документа, удостоверяющего личность, адрес
            регистрации, адрес электронной почты, адрес доставки, статистические
            и иные данные (cookies) и др. Компания осуществляет обработку
            персональных данных способом сбора, систематизации, накопления,
            хранения, уточнения (обновления, изменения), использования, передачи
            (предоставления, доступа), обезличивания, блокирования, уничтожения.
          </p>
          <p>
            <span>Технологии «cookies» Cookies –</span> небольшие по размеру
            текстовые файлы, хранящиеся в браузере Пользователей МП.
          </p>
          <p>
            На совокупности различных веб-проектов Компании (при просмотре
            Приложения) происходит автоматический сбор (из Cookies) следующих
            обезличенных статистических данных о Пользователе МП, в том числе:
          </p>
          <ul>
            <li>
              тип выполненного на сайте действия (клик, наведение курсора и
              т.п.);
            </li>
            <li>дата и время выполнения действия;</li>
            <li>URL страницы;</li>
            <li>Referer;</li>
            <li>IP (без возможности работы с IP-адресами в статистике);</li>
            <li>User-Agent;</li>
            <li>ClientID (идентификатор браузера по файлу Cookie);</li>
            <li>экранное разрешение;</li>
            <li>класс HTML-элемента, на который происходит клик;</li>
            <li>
              данные о просматриваемых, подключаемых или отключаемых услугах
              связи в системах самообслуживания, включая: идентификаторы услуг,
              названия услуг, стоимость подключения / отключения услуг,
              абонентскую плату за услуги на момент подключения;
            </li>
            <li>
              данные о количестве просмотров карточек товаров в различных
              товарных списках, кликах по выбранным карточкам товаров,
              добавлениях в корзину, удалениях из корзины в связке с данными о
              стоимости таких товаров;
            </li>
            <li>
              данные о содержании, стоимости, статусе выполнения заказов
              товаров, оформленных пользователями на веб-проектах Компании;
            </li>
            <li>
              данные о фактах заполнения форм на веб-сайтах, включая ошибки при
              их заполнении.
            </li>
          </ul>
          <p>
            Компанией обрабатываются статистические и иные данные о Пользователе
            МП, в том числе с использованием соответствующих систем, обработка
            так же может быть поручена третьим лицам для проведения
            исследований, выполнения работ или оказания услуг.
          </p>
          <p>
            Пользователь МП может самостоятельно управлять файлами Cookies путем
            изменения настроек браузера. Изменения пользовательских настроек, в
            результате которых файлы Cookies будут заблокированы или удалены,
            могут привести к недоступности отдельных компонентов МП.
          </p>
          <h1>Цели сбора и обработки персональных данных Пользователя</h1>
          <p>
            Компания обрабатывает персональные данные Пользователя МП для цели
            предоставления сервисного и информационно-справочного обслуживания,
            в том числе предоставления информации об услугах Компании и
            партнеров Компании, включая:
          </p>
          <ul>
            <li>идентификации Пользователя МП;</li>
            <li>регистрации в системе самообслуживания (Личном кабинете);</li>
            <li>оформления необходимых договоров;</li>
            <li>
              предоставления Пользователя МП информации о Компании и оказываемых
              услугах;
            </li>
            <li>
              ознакомления Пользователя МП с правовыми документами Компании, а
              также реализации полномочий и обязанностей, возложенных на
              Компанию законодательством Республики Таджикистан;
            </li>
            <li>
              установления обратной связи, включая направление уведомлений,
              запросов, касающихся оказания услуг, обработка запросов и заявок
              от Пользователя МП;
            </li>
            <li>
              определения локации Пользователя МП для обеспечения безопасности,
              предотвращения мошенничества;
            </li>
            <li>
              подтверждения достоверности и полноты персональных данных,
              предоставленных Пользователем МП;
            </li>
            <li>
              предоставления доступа Пользователя МП к сайтам или к сервисам
              партнеров Компании с целью получения продуктов, обновлений и
              услуг;
            </li>
            <li>для других целей с согласия Пользователя МП.</li>
          </ul>
          <p>
            Персональные данные Пользователя МП могут быть переданы Компанией
            третьим лицам на основании волеизъявления/согласия Пользователя МП,
            для целей оказания Пользователям МП услуг и сервисов в соответствии
            с условиями их предоставления, принятыми Пользователем МП, для
            выполнения Компанией функций, полномочий и обязанностей, возложенных
            на Компанию законом, а также для осуществления прав и законных
            интересов Компании или третьих лиц.
          </p>
          <p>
            При обработке персональных данных пользователей МП руководствуется
            Законом РТ «О защите персональных данных».
          </p>
          <p>
            По достижению целей обработки, а также наступления иных оснований,
            предусмотренных законодательством Республики Таджикистан в области
            обработки и защиты персональных данных, персональные данные
            Пользователей МП уничтожаются.
          </p>
          <h1>Права Пользователя мобильного приложения</h1>
          <p>
            Пользователи МП вправе реализовать свои права, предусмотренные
            законодательством Республики Таджикистан о персональных данных, в
            том числе, но не ограничиваясь:
          </p>
          <ul>
            <li>
              уточнять, обновлять свои персональные данные, требовать их
              блокирования или уничтожения;
            </li>
            <li>
              запрашивать у Компании перечень обрабатываемых персональных
              данных, правовых оснований обработки, источники их получения,
              информацию о сроках обработки и хранения, а также иные сведения,
              связанные с обработкой своих персональных данных.
            </li>
          </ul>
          <h1>Срок обработки персональных данных</h1>
          <p>
            Обработка персональных данных Пользователя МП осуществляется в
            течение срока, необходимого для достижения цели обработки в рамках
            предоставления сервисного и информационно-справочного обслуживания,
            или в течение срока действия согласия Пользователя МП на обработку
            персональных данных. Пользователя МП вправе в любое время отозвать
            согласие на обработку его персональных данных путём направления
            письменного заявления по адресу: 734242, Республика Таджикистан, г.
            Душанбе, ул. Тилло Пулоди, 41.
          </p>
          <h1>Обеспечение безопасности персональных данных</h1>
          <p>
            Компания гарантирует Пользователям МП надлежащую защиту персональных
            данных при их обработке. Компания применяет правовые,
            организационные и технические меры для защиты персональных данных от
            несанкционированного или случайного доступа к ним, а также от иных
            неправомерных действий в отношении персональных данных, в том числе:
          </p>
          <ul>
            <li>
              разграничение доступа к информационным системам, обрабатывающим
              персональные данные;{" "}
            </li>
            <li>
              предотвращение внедрения в информационные системы, обрабатывающие
              персональные данные, вредоносных программ и программных закладок;
            </li>
            <li>
              межсетевое экранирование с целью управления доступом, фильтрации
              сетевых пакетов и трансляции сетевых адресов для скрытия структуры
              информационных систем, обрабатывающих персональные данные;
            </li>
            <li>
              обнаружение вторжений в информационные системы, обрабатывающие
              персональные данные, нарушающие или создающие предпосылки к
              нарушению установленных требований по обеспечению безопасности
              персональных данных;
            </li>
            <li>
              анализ защищенности информационных систем персональных данных,
              предполагающий применение специализированных программных средств.
            </li>
          </ul>
          <p>
            Доступ к персональным данным Пользователей МП предоставляется только
            уполномоченным работникам Компании.
          </p>
          <h1>Заключительные положения </h1>
          <p>
            Компания вправе вносить изменения или дополнения в настоящую
            Политику в случае необходимости, а также в случае внесения
            соответствующих изменений в действующее законодательство Республики
            Таджикистан о персональных данных. Пользователь МП всегда может
            ознакомиться с актуальной версией Политики на сайте Компании.
            Продолжая пользоваться МП, Пользователь МП подтверждает согласие с
            внесенными изменениями в Политику.
          </p>
          <p>
            При внесении изменений в актуальной редакции указывается дата
            последнего обновления. Новая редакция Политики вступает в силу с
            момента ее размещения, если иное не предусмотрено новой редакцией
            Политики.
          </p>
          <p>
            К настоящей Политике и отношениям между Пользователем и Компанией,
            возникающим в связи с применением Политики конфиденциальности,
            подлежит применению право Республики Таджикистан.
          </p>
          <p>
            Настоящая Политика применима только к данному Приложению. Компания
            не контролирует и не несет ответственность за сайты и мобильные
            приложения третьих лиц, на которые Пользователь может перейти по
            ссылкам, доступным в данном МП, в том числе в результатах поиска. На
            таких сайтах и в приложениях у Пользователя может собираться или
            запрашиваться иная персональная информация, а также могут
            совершаться иные действия.
          </p>
          <p>
            Компания в общем случае не проверяет достоверность персональной
            информации, предоставляемой Пользователями, и не осуществляет
            контроль за их дееспособностью. Однако, приложение исходит из того,
            что Пользователь предоставляет достоверную и достаточную
            персональную информацию по вопросам, предлагаемым Компанией
            посредством МП, и поддерживает эту информацию в актуальном
            состоянии.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
