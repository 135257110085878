import React from 'react'
import './AdminServices.scss'
import AdminHeaderModule from '../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule'
import AdminMenuModule from '../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule'
import HomeGeneralSlider from '../../Home/HomeGeneralSlider/HomeGeneralSlider'
import AdminServicesCard from './AdminServicesCard/AdminServicesCard'
const AdminServices = () => {
  return (
    <div className="adminservices_container">
    <AdminHeaderModule />
    <div className="adminservices_block">
      <AdminMenuModule />
      <div className="adminservices_item">
        <HomeGeneralSlider />
        <div className="adminservicesCards_block">
          <h1>Доступные услуги</h1>
          <div className="adminservicesCards_item">
            <AdminServicesCard />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AdminServices
