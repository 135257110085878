import React from 'react'
import './AdminLogin.scss'
import AdminLoginCard from './AdminLoginCard/AdminLoginCard'
const AdminLogin = ({login, err}) => {
  return (
    <div className='adminLogin_container'>
      <div className="adminLogin_block">
      <AdminLoginCard login={login} err={err}/>
      <div className="adminLoginSecurity_text">
        <p>© 2024 OOO «Нет Солюшенс». Все права защищены
        При входе на ресурс, вы принимаете <span>условия доступа</span> и <span>политику обработки ПДн в ООО «Нет Солюшенс»</span></p>
      </div>
      </div>
    </div>
  )
}

export default AdminLogin
