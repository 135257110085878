import React, { useState } from 'react';
import './VacanciesItemForm.scss';
import MyButton from '../../../UI/MyButton/MyButton';

const VacanciesItemForm = ({ vacancyTitle }) => {
 
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: '',
    city: '',
    resumeLink: '',
    letter: ''
  });
  const [file, setFile] = useState(null);  
  const [fileName, setFileName] = useState('');  

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

 
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      setFileName(selectedFile.name);  
    } else {
      setFileName('');  
    }
  };

 
  const sendResume = (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }
    if (file) {
      form.append('file_1', file);  
    } else {
      alert('Файл обязателен для отправки');
      return;
    }

 
    form.append('vacancyTitle', vacancyTitle);

 
    fetch('https://nets.tj/mail_send', {
      method: 'POST',
      body: form,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        
        setFormData({
          name: '',
          surname: '',
          phone: '',
          email: '',
          city: '',
          resumeLink: '',
          letter: ''
        });
        setFile(null);
        setFileName('');  
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className='vacanciesItemForm_container'>
      <div className="vacanciesItemForm_block">
        <div className="vacanciesItemForm">
          <div className="vacanciesItemForm_title">
            <h1>Отклик на вакансию</h1>
            <p>{vacancyTitle}</p> {/* Отображение названия вакансии */}
          </div>
          <div className="vacanciesItemForm_item">
            <form onSubmit={sendResume}>
              <div className="vacanciesItemFormInput_block">
                <div className="vacanciesItemFormInput_item">
                  <p>Имя</p>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="vacanciesItemFormInput_item">
                  <p>Фамилия</p>
                  <input
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="vacanciesItemFormInput_block">
                <div className="vacanciesItemFormInput_item">
                  <p>Телефон</p>
                  <input
                    type="number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="vacanciesItemFormInput_item">
                  <p>Почта</p>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="vacanciesItemFormInputHref_block">
                <p>Город</p>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
              <div className="vacanciesItemFormInputHref_block">
                <p>Ссылка на резюме</p>
                <input
                  type="url"
                  name="resumeLink"
                  value={formData.resumeLink}
                  onChange={handleInputChange}
                />
              </div>
              <h6>или</h6>
              <div className="vacanciesItemFormFile_block">
                <div className="vacanciesItemFormFile_item">
                  <div className="vacanciesItemFormFile">
                    <input type="file" onChange={handleFileChange} />
                    <p>
                      {fileName ? fileName : 'Выберите файл'}{' '}
                      <span>{!fileName && 'или переместите резюме сюда'}</span>
                    </p>
                  </div>
                </div>
                <h5>Форматы файла: DOC, DOCX, PDF. Не более 5 МБ</h5>
              </div>
              <div className="vacanciesItemFormCommet_block">
                <div className="vacanciesItemFormCommet_item">
                  <h4>Сопроводительное письмо</h4>
                  <p>Необязательно</p>
                </div>
                <textarea
                  name="letter"
                  value={formData.letter}
                  onChange={handleInputChange}
                />
              </div>
              <h2>
                Я соглашаюсь<span> на обработку моих персональных данных</span>
              </h2>
              <div className="vacanciesItemForm_btn">
                <MyButton type="submit" name={'Отправить отклик'} width={'250px'} height={'40px'} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacanciesItemForm;
