import React from "react";
import "./AdminMenuLinkCard.scss";
import AdminMenuLinkCardText from "./AdminMenuLinkCardText/AdminMenuLinkCardText";
import { NavLink } from "react-router-dom";
import { adminMenuLink } from "../../../../Modules/AdminModules/AdminMenuModule/AdminMenuLogic/AdminMenuLogic";
const AdminMenuLinkCard = () => {
  return (
    <div className="adminMenuLinkCard_block"> 
      {
        adminMenuLink?.map((e)=> 
 
        <AdminMenuLinkCardText icon={e.icon} text={e.text} line={e.line} key={e.id} link={e.link}/>
 
        )
      }
    </div>
  );
};

export default AdminMenuLinkCard;
