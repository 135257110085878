import React, { useEffect, useState } from 'react'
import AdminTarifs from '../../../Components/Admin/AdminTarifs/AdminTarifs'
import { homeTerif } from '../../HomeModule/HomeLogic/HomeLogic';

const AdminTarifsModule = () => {
  const [adminTarifs, setAdminTarifs] = useState([]);
  const getAdminarif = () => {
    homeTerif()
      .then((res) => {
        setAdminTarifs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=> getAdminarif(), [])
  return (
    <div>
      <AdminTarifs adminTarifs={adminTarifs}/>
    </div>
  )
}

export default AdminTarifsModule
