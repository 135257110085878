import React from 'react'
import AdminServices from '../../../Components/Admin/AdminServices/AdminServices'

const AdminServicesModule = () => {
  return (
    <div>
      <AdminServices/>
    </div>
  )
}

export default AdminServicesModule
