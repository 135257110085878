import React from 'react'
import AdminLoginModule from '../../../Modules/AdminModules/AdminLoginModule/AdminLoginModule'

const AdminLoginPage = () => {
  return (
    <div>
      <AdminLoginModule/>
    </div>
  )
}

export default AdminLoginPage
