import React from "react";
import "./AdminHomePackagesCard.scss";
import MyButton from "../../../../../UI/MyButton/MyButton";
const AdminHomePackagesCard = () => {
  return (
    <div className="adminHomePackagesCard_block">
      <div className="adminHomePackagesCard_item">
        <h3>Название Пакета</h3>
        <p>Активен до 23.09.24</p>
      </div>
      <MyButton
        name={"Подробнее"}
        height={"37px"}
        width={"100%"}
        grey={true}
        size={"14px"}
      />
    </div>
  );
};

export default AdminHomePackagesCard;
