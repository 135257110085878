import React from "react";
import "./AdminTarifs.scss";
import AdminHeaderModule from "../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule";
import AdminMenuModule from "../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule";
import HomeGeneralSlider from "../../Home/HomeGeneralSlider/HomeGeneralSlider";
import AdminTarifsCard from "./AdminTarifsCard/AdminTarifsCard";
const AdminTarifs = ({adminTarifs}) => {
  return (
    <div className="adminTarifs_container">
      <AdminHeaderModule />
      <div className="adminTarifs_block">
        <AdminMenuModule />
        <div className="adminTarifs_item">
          <HomeGeneralSlider />
          <div className="adminTarifsCards_block">
            <h1>Выберите тариф</h1>
            <div className="adminTarifsCards_item">
              <AdminTarifsCard adminTarifs={adminTarifs}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTarifs;
