import React from 'react'
import AdminMenu from '../../../Components/Admin/AdminMenu/AdminMenu'

const AdminMenuModule = () => {
  return (
    <div>
      <AdminMenu/>
    </div>
  )
}

export default AdminMenuModule
