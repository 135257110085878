import React from 'react'
import './AdminHomeTrafficsCardBattty.scss'
const AdminHomeTrafficsCardBattty = () => {
  return (
    <div className='adminHomeTrafficsCardBattty_block'>
        <div className="adminHomeTrafficsCardBattty_item">
            <div className="adminHomeTrafficsCardBatttyText_block">
                <div className="adminHomeTrafficsCardBatttyText_item">
                    <div className="adminHomeTrafficsCardBatttyText_line" style={{width: '50%'}}></div>
                    <div className="adminHomeTrafficsCardBatttyText">
                        <h2>6GB</h2>
                        <h3>24GB</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className="adminHomeTrafficsCardBattty_radius"></div>
    </div>
  )
}

export default AdminHomeTrafficsCardBattty
