import React from "react";
import "./AdminHome.scss";
import AdminMenuModule from "../../../Modules/AdminModules/AdminMenuModule/AdminMenuModule";
import AdminHomeTrafficsCard from "./AdminHomeTrafficsCard/AdminHomeTrafficsCard";
import AdminHeaderModule from "../../../Modules/AdminModules/AdminHeaderModule/AdminHeaderModule";
import HomeGeneralSlider from "../../Home/HomeGeneralSlider/HomeGeneralSlider";
import AdminHomeBalanceCard from "./AdminHomeBalanceCard/AdminHomeBalanceCard";
import AdminHomeTarrifCard from "./AdminHomeTarrifCard/AdminHomeTarrifCard";
import AdminHomePackages from "./AdminHomePackages/AdminHomePackages";
const AdminHome = () => {
  return (
    <div className="adminHome_container">
      <AdminHeaderModule />
      <div className="adminHome_block">
        <AdminMenuModule />
        <div className="adminHome_item">
          <HomeGeneralSlider />
          <div className="adminHomeTopCard_block">
            <div className="adminHomeTopCard_item">
              <AdminHomeBalanceCard />
            </div>
            <div className="adminHomeTopCard_item">
              <AdminHomeTarrifCard />
            </div>
          </div>
          <div className="adminHomeTopCardBottom_block">
            <div className="adminHomeTopCardBottom_item">
              <AdminHomeTrafficsCard />
            </div>
            <div className="adminHomeTopCardBottom_item">
              <AdminHomePackages />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
