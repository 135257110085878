import React, { useState } from 'react'
import './AdminLoginCard.scss'
import logo  from '../../../../Assets/General/Img/logo_nets.svg'
const AdminLoginCard = ({login, err}) => {
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  return (
    <div className='adminLoginCard_block'>
      <img src={logo} alt="" />
      <h2>Вход в личный кабинет</h2>
      <div className="adminLoginCard_item">
      <input type="number" placeholder='ID' value={id} onChange={(e)=> setId(e.target.value)}/>
      <input type="password" placeholder='Пароль' value={password} onChange={(e)=> setPassword(e.target.value)} />
      <p>{err}</p>
      <button onClick={()=> login(+id, password)}>Вход</button>
      </div>
    </div>
  )
}

export default AdminLoginCard
