import React from 'react'
import AdminHomeModule from '../../../Modules/AdminModules/AdminHomeModule/AdminHomeModule'

const AdminHomePage = () => {
  return (
    <div>
      <AdminHomeModule/>
    </div>
  )
}

export default AdminHomePage
