import React from 'react'
import AdminServicesModule from '../../../Modules/AdminModules/AdminServicesModule/AdminServicesModule'

const AdminServicesPage = () => {
  return (
    <div>
      <AdminServicesModule/>
    </div>
  )
}

export default AdminServicesPage
