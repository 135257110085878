import React from 'react'
import AdminHeader from '../../../Components/Admin/AdminHeader/AdminHeader'
import AdminMenuMobi from '../../../Components/Admin/AdminMenuMobi/AdminMenuMobi'

const AdminHeaderModule = () => {
  return (
    <div>

      <AdminHeader/>
      <AdminMenuMobi/>
    </div>
  )
}

export default AdminHeaderModule
